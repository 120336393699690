module.exports = {
  api: {
		baseUrl: 'https://www.zdnet.com/irb/lib/api',
    clientInfoUrl: 'https://www.zdnet.com/irb/client-info',
		apiBase: {
			prod: 'https://www.zdnet.com/irb/lib/api',
			stage: 'https://test.zdnet.com/irb/lib/api',
		},
	},
  dfpPath: {
		property: 'zdnet',
	},
	dynamicBidders: {
     // magnite
		rubicon: (existingBidderParams) => {
			if (!existingBidderParams.included) return null;
			return {
				siteId: '572424',
				accountId: 11576,
			};
		},
	},
	intentIQ: {
		enabled: true,
		params: {
			domainName: 'zdnet.com',
		},
	},
	prebid: {
		ids: {
			liveIntent: {
				userSyncConfig: {
					params: {
						publisherId: '67193',
					},
				},
			},
			unifiedId: {
				enabled: true,
			},
		},
		pbjsConfig: {
			floors: {
				enabled: true,
				data: {
					schema: {
						delimiter: '|',
						fields: ['topAdUnit', 'mediaType'],
					},
					values: {
						'/22309610186/aw-zdnet|*': 0.75,
						'/22309610186/maw-zdnet|*': 0.75,
						'/22309610186/aw-zdnet|*|video': 0.5,
						'/22309610186/maw-zdnet|*|video': 0.5,
						'/22309610186/vaw-zdnet|*|*': 0.5,
						'*|*|video': 0.5,
					},
				},
			},
			ortb2: {
				site: {
					cat: ['IAB19'],
					cattax: 1,
				},
				user: {},
			},
		},
	},
	targeting: {
		seats: {
			session: 4,
			subsession: 6,
		},
	},

	// Note that ZDNet uses a function which is setup on site-side to generate the incremenatal names
	// ,
	// generateIncrementalUnitCode: (templateCode, increment, template) => {
	//     if(template && template.isVideo){
	//         return templateCode
	//     }
	//     return templateCode + (1 < increment ? "-" + increment : '');
	// }
};
